export default {
  google: {
    maps: {
      apiKey: 'AIzaSyCGXMgM2THiVdCaNk3_TdHrge11LaJb8YA'
    },
    analytics: {
      apiKey: 'G-SZFFMDLRZQ'
    }
  },
  mapbox: {
    apiKey: 'pk.eyJ1IjoiYWR2aXNyIiwiYSI6ImNsbzRoZXFmNDAxbXIybHRmNm92N3Y3bXEifQ.BnHhPxySzTKSL_ywlvsd0w'
  },
  rollbar: {
    accessToken: 'f6f0cb5d42c5441b898b38357d71d93f'
  }
}